* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
}

html, body, #root, .App  {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none;
}

[role="button"] {
  cursor: pointer;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
}


input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

a {
  text-decoration: unset;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}
:root {
  --primaryBrand: #ff7300;
  --insyncOrange: #ff8826;
  --primaryBrandLight: #ffeee0;
  --primaryWhite:#fff9f5;
  --black: #242424;
  --raisinBlack: #242427;
  --arsenic: #404042;
  --darkGray: #5c5c5c;
  --gray: #aeaeae;
  --brightGray: #ebebeb;
  --platinum: #e5e5e5;
  --whiteSecondary: #fafafa;
  --white: #ffffff;
  --red: #f91e1e;
  --error: #eb5c39;
  --success:#49b851;
}
